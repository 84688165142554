<template>
  <div class="container">

    <ProfileTitleComponent
      title="Videos Management"
      subtitle="Add a new Video"
    />

    <create-or-modify-video
      :categories="categories"
      :create="false"
      :originalVideo="video"
      @create-or-modify-video="modifyVideo"
      @delete-video="deleteVideo"
    />

  </div>
</template>

<script>
import { APIPrivatePersonConnected } from '@/api/APIPrivatePersonConnected'
import ProfileTitleComponent from '@/components/user/profile/ProfileTitleComponent'
import CreateOrModifyVideo from '@/components/video/CreateOrModifyVideo.vue'

const apiPrivatePersonConnected = new APIPrivatePersonConnected()

export default {
  name: 'LearnNewVideo',
  components: {
    ProfileTitleComponent,
    CreateOrModifyVideo,
  },
  data() {
    return {
      categories: [
        'Recreational Pilot',
        'Professional Pilot',
        'Commercial Crew',
        'Aircraft Mechanic',
        'Drone Operator',
        'Engineering',
        'Traffic Controller',
      ],
      video:{
        categories: [],
        title: '',
        description: '',
        file: null,
      }
    }
  },
  mounted() {
    this.$store.commit('loadFromAPI')
    this.getVideo()
    this.$store.commit('endLoadingFromAPI')
  },
  methods: {
    async deleteVideo () {
      this.$store.commit('loadFromAPI')
      await apiPrivatePersonConnected.deleteVideo(this.video.id)
      this.$store.commit('endLoadingFromAPI')
      this.$root.$bvToast.toast(`Your video has been deleted`, {
        title: 'Video deleted',
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      })
      this.$router.push('/learn/my-videos')
    },
    async getVideo () {
      apiPrivatePersonConnected.getVideo(this.$route.params.id)
      .then((response) => {
        this.video = response.data
      })
      .catch((error) => {
        throw new Error('getAd API Error : ' + String(error))
      })
    },
    async modifyVideo(video) {
      this.$store.commit('loadFromAPI')
      const formData = new FormData()
      formData.append('categories', JSON.stringify(video.categories))
      formData.append('title', video.title)
      formData.append('description', video.description)

      await apiPrivatePersonConnected.modifyVideo(this.$route.params.id, formData)
      this.$store.commit('endLoadingFromAPI')
      this.$root.$bvToast.toast(`Your video data have been modified`, {
        title: 'Video data modified',
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      })
      this.$router.push('/learn/my-videos')
    },
  },
}
</script>

