<template>
  <div class="container">

    <ProfileTitleComponent
      title="Videos Management"
      subtitle="Add a new Video"
    />

    <create-or-modify-video
      :categories="categories"
      :create="true"
      @create-or-modify-video="createVideo"
    />

  </div>
</template>

<script>
import { APIPrivatePersonConnected } from '@/api/APIPrivatePersonConnected'
import ProfileTitleComponent from '@/components/user/profile/ProfileTitleComponent'
import CreateOrModifyVideo from '@/components/video/CreateOrModifyVideo.vue'

const apiPrivatePersonConnected = new APIPrivatePersonConnected()

export default {
  name: 'LearnNewVideo',
  components: {
    ProfileTitleComponent,
    CreateOrModifyVideo,
  },
  data() {
    return {
      categories: [
        'Recreational Pilot',
        'Professional Pilot',
        'Commercial Crew',
        'Aircraft Mechanic',
        'Drone Operator',
        'Engineering',
        'Traffic Controller',
      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    }
  },
  
  methods: {
    async createVideo(video) {
      this.$store.commit('loadFromAPI')
      const formData = new FormData()
      formData.append('categories', JSON.stringify(video.categories))
      formData.append('title', video.title)
      formData.append('description', video.description)
      formData.append('file', video.file)

      await apiPrivatePersonConnected.createVideo(formData)
      this.$store.commit('endLoadingFromAPI')
      this.$root.$bvToast.toast(`Your video has been uploaded`, {
        title: 'Video uploaded',
        autoHideDelay: 5000,
        appendToast: false,
        variant: "success"
      })
      this.$router.push('/learn/my-videos')
    },
  },
}
</script>

