<template>
  <div>

    <b-modal
      v-model="deleteVideoModal"
      content-class="airspirit-modal"
      hide-footer
      header-class="airspirit-modal-header"
      size="md"
    >
      <template #modal-header>
        <div 
          @click="deleteVideoModal = false"
          class="airspirit-modal-header--close-icon"
          style="float: left"
        >
          <img 
            :src="require('@/assets/images/pictos/croix.svg')"
          />
        </div>
        <div
          class="airspirit-modal-header--title"
          style=""
        >
          Delete video 
        </div>
  
      </template>
      <p>
        Are you sure to delete this video ?
      </p>
      <button
        class="profile-delete-button"
        style="width: 100%;"
        @click="deleteVideo"
      >
        Confirm video deletion
      </button>
    </b-modal>
    
    <div class="video-section mt-4">
      <img
        src="@/assets/images/ads/flag_1.svg"
      />
      <p>
        <strong style="text-decoration: underline; text-underline-offset: 10px;"> Select one or several categories </strong>
      </p>
    </div>

    <div class="video-categories mt-4">
      <div
        v-for="category in categories"
        :key="category"
        class="video-category"
        @click="toggleCategory(category)"
        :style="categoryStyle(category)"
      >
        <div>{{ category }}</div>
      </div>
    </div>

    <p
      v-if="$v.video.categories.$invalid && showErrorMessage"
      class="text-danger"
    >
      Please choose a category
    </p>

    <div class="video-section mt-4">
      <img
        src="@/assets/images/ads/flag_2.svg"
      />
      <p>
        <strong style="text-decoration: underline; text-underline-offset: 10px;"> Video details </strong>
      </p>
    </div>

    <input
      class="registration-input modify-video-inputs-input mt-2"
      :class="{'is-invalid-input': $v.video.title.$dirty && $v.video.title.$error}"
      type="text"
      maxlength="200"
      placeholder="Title"
      v-model="video.title"
      @input="$v.video.title.$touch()"
    />
    <p
      v-if="$v.video.title.$invalid && showErrorMessage"
      class="text-danger"
    >
      Please enter a title (3-200 characters)
    </p>

    <textarea
      class="registration-input modify-video-inputs-input mt-2"
      :class="{'is-invalid-input': $v.video.description.$dirty && $v.video.description.$error}"
      placeholder="Description"
      v-model="video.description"
      @input="$v.video.description.$touch()"
    />
    <p
      v-if="$v.video.description.$invalid && showErrorMessage"
      class="text-danger"
    >
      Please enter a description (3-200 characters)
    </p>

    <div 
      v-if="create"
      class="row mt-2"
    >
      <div class="col-12">
        <div class="add-video-file">
          <label for="input-upload"></label>
          <p>
            <strong>Upload your video</strong>
          </p>
          <input 
            type="file" 
            accept=".mp4, .avi, .mov"
            id="input-upload" 
            required="required" 
            class="add-video-file-input"
            @change="onVideoFileChange"
          >
        </div>

        <div
          v-if="video.file"
          class="video-section mt-4"
        >
          <strong>
            Video uploaded:
          </strong>
          {{ video.file.name }}
        </div>
      </div>
    </div>

    <div class="profile-footer">
      <div 
        @click='$router.go(-1)'
        class="profile-back-pc-button"
      >
        <img
          src="@/assets/images/button/back/back_pc.svg"
          alt="back"
        />
      </div>
      <button
        v-if="create"
        class="profile-validation-button"
        @click="createOrModifyVideo"
      >
        Add video 
      </button>
      <div v-else>
        <button
          class="profile-delete-button mr-2"
          @click="deleteVideoModal = true"
        >
          Delete video
        </button>
        <button
          class="profile-validation-button"
          @click="createOrModifyVideo"
        >
          Update video
        </button>
      </div>
    </div>
  </div>
      
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'


export default {
  name: 'CreateOrModifyAd',
  props: {
    categories : {
      type: Array,
      default: () => []
    },
    create: {
      type: Boolean,
      default: false
    },
    originalVideo: {
      type: Object,
      default: null,
    }
  },
  mixins: [validationMixin],
  data: function () {
    return {
      video: {
        categories: [],
        title: '',
        description: '',
        file: null,
      },
      deleteVideoModal: false,
      showErrorMessage: false
    }
  },
  watch: {
    originalVideo: function(value) {
      this.video = value
    },
  },
  mounted () {
    if (this.originalVideo) {
      this.video = this.originalVideo
    }
  },
  validations: {
    video: {
      categories: {
        required
      },
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(200)
      },
      description: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000)
      },
      file: {
        // pictures is an aray, validate if there is at least one item in it
        required: requiredIf(function() {
          return this.create
        })
      },
    }
  },
  methods: {
    categoryStyle(category) {
      return this.video.categories.includes(category) ? `border-bottom: solid 2px black; padding-bottom: 5px;` : ''
    },
    createOrModifyVideo() {
      this.$v.$touch()
      this.showErrorMessage = false
      if (this.$v.video.$invalid) {
        this.showErrorMessage = true
        return
      }
      else {
        this.$emit('create-or-modify-video', this.video)
      }
      
    },
    deleteVideo() {
      this.$emit('delete-video', this.video)
    },

    toggleCategory(category) {
      if (this.video.categories.includes(category)) {
        this.video.categories = this.video.categories.filter(c => c !== category)
      } else {
        this.video.categories.push(category)
      }
    },
    onVideoFileChange(event) {
      const file = event.target.files[0]
      if (file) {
        this.video.file = file
      }
    },
  }
}
</script>

<style>

.video-categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

@media screen and (min-width: 768px) {
  .video-categories {
    padding-left: 50px;
    padding-right: 50px;
  }
}
  
.video-category {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 10px;
  text-align: center;
  flex: 1 0 21%; /* explanation below */
}

@media screen and (min-width: 768px) {
  .video-category {
    flex: unset;
  }
}

.video-category > img {
  height: 35px;
}

.video-section {
  display: flex;
  gap: 10px;
}

.video-section > img {
  height: 20px;
  margin-top: 5px;
}
.modify-video-inputs {
  display: flex;
  flex-wrap: wrap;

  gap: 10px;
}

.modify-video-inputs-input {
  display: flex;
  flex-direction: column;
}

.add-video-file {
  display: flex;
  position: relative;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: center;
  width: 100%; 
  transition: box-shadow .2s ease-in-out;
  border-radius: 6px;
  border: 1px dashed #ccc;
  text-align: center;
  cursor: pointer;
  height: 200px;
}


.add-video-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

</style>
