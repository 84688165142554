<template>
  <div class="container">
    <div class="annonces-menu">
      <div class="video-categories mt-4">

        <div
          @click="clickOnLike"
          class="p-1 text-center categories-menu-tag-button"
        >
          <img
            class="categories-menu-tag-button-img"  
            src="@/assets/images/video/like_icon_black.svg"
            alt="like"
            width="40px"
          />
          <div class="categories-menu-tag-button-text">ONLY LIKES</div>
          <div 
            class="categories-menu-tag-button-border"
            :style="onlyLiked ? 'border-bottom: solid 2px black;' : ''"
          ></div>
        </div>
        <div
          v-for="category in categories"
          :key="category"
          class="video-category"
          @click="toggleCategory(category)"
          :style="categoryStyle(category)"
        >
          <div>{{ category }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div 
        v-for="video in videos"
        :key="video.id"
        class="col-12 col-md-6 p-2 mt-4"
      > 
        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe 
            :src="`https://player.vimeo.com/video/${video['vimeo_id']}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
            style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          />
        </div>

        <div class="video-details mt-2">
          <div>
            <strong>{{video.title}}</strong>
            <br>
            <span 
              v-if="video.categories"
              class="text-muted"
            > 
              {{video.categories?.join(', ')}}
            </span>
          </div>
          <div>
            <img
              @click="likeVideo(video)"
              :src="likeImage(video)"
              class="video-item-picture--like"
              width="40px"
              alt="like"
            />
          </div>
        </div>
      </div>
    </div>

    <div 
      v-if="hasMore" 
      class="text-center has-more-videos-button mt-4">
      <button
        class="profile-validation-button"
        @click="nextVideos"
      >
        More Videos 
      </button>
    </div>
    
    <searchbar-mobile 
      v-if="isMobile()"
      @search="search"
      placeholder="Search on Videos"
    />
  </div>
</template>

<script src="https://player.vimeo.com/api/player.js"></script>

<script>
import { APIVideo } from '@/api/APIVideo'
import { APIUserConnected } from '@/api/APIUserConnected'
import SearchbarMobile from '@/components/search/SearchbarMobile.vue'
import { mobileDetectionMixins } from '@/mixins/mobileDetection'

const apiVideo = new APIVideo()
const apiUserConnected = new APIUserConnected()


export default {
  name: 'LearnPage',
  components: { SearchbarMobile },
  mixins: [mobileDetectionMixins],
  data() {
    return {
      categories: [
        'Recreational Pilot',
        'Professional Pilot',
        'Commercial Crew',
        'Aircraft Mechanic',
        'Drone Operator',
        'Engineering',
        'Traffic Controller',
      ],
      categoriesChosen: [],
      hasMore: false,
      onlyLiked: false,
      page: 1,
      videos: [],
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    userId () {
      return this.$store.state.user.userPk
    }
  },
  async mounted () {
    if ('search' in this.$route.query) {
      this.$eventHub.$emit('setSearch', this.$route.query.search)
    }

    this.$eventHub.$on('search', () => {
      this.search()
    })
    
    if ('categories' in this.$route.query && this.$route.query.categories) {
      this.categoriesChosen = this.$route.query.categories.split(',')
    }
    this.getVideos()
  },
  async created() {
    this.$store.commit('loadFromAPI')
    await this.getVideos()
    this.$store.commit('endLoadingFromAPI')
  },
  methods: {
    categoryStyle(category) {
      return this.categoriesChosen.includes(category) ? `border-bottom: solid 2px black; padding-bottom: 5px;` : ''
    },
    clickOnLike () {
      if (this.isAuthenticated) {
        this.onlyLiked = !this.onlyLiked
        if (this.onlyLiked) {
          this.getVideosLiked()
        } else {
          this.getVideos()
        }
      } else {
        this.$store.commit('set', ['loginModalNotRedirect', true])
        this.$store.commit('openLoginModal')
      }
    },
    filterVideos () {
      const data = {
        'categories': this.categoriesChosen,
      }
      this.$router.push({path: "/learn", query: data})
      this.getVideos()
    },
    getVideos (isNext=false) {
      this.$store.commit('loadFromAPI')
      apiVideo.getVideos(this.page, this.categoriesChosen, this.searchText)
      .then((result) => {
        this.hasMore = result.data['hasMore']
        this.videos = isNext ? this.videos.concat(result.data['videos']) : result.data['videos']
      })
      .catch((error) => {
        throw new Error('getVideos getAds API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    getVideosLiked (isNext=false) {
      this.$store.commit('loadFromAPI')
      const data = {
        'search': this.searchText,
        'categories': this.categoriesChosen,
        'page': this.page,
      }
      apiUserConnected.getVideosLiked(data)
      .then((result) => {
        this.hasLoaded = true
        // translate newsletters
        this.hasMore = result.data['hasMore']
        this.videos = isNext ? this.videos.concat(result.data['videos']) : result.data['videos']
      })
      .catch((error) => {
        throw new Error('ProspectsList getProspects API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    likeImage (video) {
      return video.likes.includes(Number(this.userId))
        ? require('@/assets/images/video/like_icon_full_black.svg')
        : require('@/assets/images/video/like_icon_black.svg')
    },
    likeVideo (video) {
      // if not connected
      if (!this.isAuthenticated) {
        this.$store.commit('set', ['loginModalNotRedirect', true])
        this.$store.commit('openLoginModal')
        return
      }
      this.$store.commit('loadFromAPI')
      apiUserConnected.likeVideo(video.id)
      .then(() => {
        this.videos = this.videos.map((item) => {
          if (item.id === video.id) {
            if (item.likes.includes(Number(this.userId))) {
              item.likes = item.likes.filter((item) => item !== Number(this.userId))
            } else {
              item.likes.push(Number(this.userId))
            }
          }
          return item
        })
      })
      .catch((error) => {
        throw new Error('NewslettersView likeNewsletter API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    nextVideos () {
      this.page += 1
      if (this.onlyLiked) {
        this.getVideosLiked(true)
      } else {
        this.getVideos(true)
      }
    },
    toggleCategory(category) {
      const index = this.categoriesChosen.indexOf(category)
      if (index === -1) {
        //this.categoriesChosen.push(category.id)
        // only one category authorized
        this.categoriesChosen = [category]
      } else {
        //this.categoriesChosen.splice(index, 1)
        // only one category authorized
        this.categoriesChosen = []
      }
      this.filterVideos()
      this.searchText = ''
      this.$refs.basicNavbar.searchText = ''
    },
  },
}
</script>

<style>
.video-details {
  display: flex;
  justify-content: space-between;
}

.video-item-picture--like {
  cursor: pointer;
}

.categories-menu {
  background-color: white;
  padding-right: 45px;
  padding-left: 30px;
  padding-top: 10px;
  position: relative;
  width: 100%;
  z-index: 999;
}

@media (min-width: 800px) {
  .categories-menu {
    padding-right: 100px;
    padding-left: 100px;
    margin-top: 10px;
    padding-top: 0px;
  }
}
.categories-menu-tag-buttons {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 15px;
}

.categories-menu-tag-button {
  cursor: pointer;
  position: relative;
  height: 60px;
}

@media (min-width: 800px) {
  .categories-menu-tag-button {
    height: 100px;
  }
}

.categories-menu-tag-button-img {
  display: unset !important;
  height: 25px;
  width: 25px;
}

@media (min-width: 800px) {
  .categories-menu-tag-button-img {
    height: 50px;
    width: 50px;
  }
}

.categories-menu-tag-button-text {
  font-size: 8px;
  font-weight: bold;
  margin-top: 5px;
  text-transform: uppercase;
}

@media (min-width: 800px) {
  .categories-menu-tag-button-text  {
    font-size: 10px;
  }
}

.categories-menu-tag-button-border {
  position: absolute;
  left: 25%;
  right:25%;
  margin-top: 5px;
}

@media (min-width: 800px) {
  .categories-menu-tag-button-border {
    margin-top: 10px;
  }
}

.categories-menu-vue-slick-carousel--arrow-right {
  bottom: 50%; 
  height: 15px;
  opacity: 0.3;
  position: absolute;
  right: 30px;
}

.categories-menu .slick-prev {
  display: none !important;
}

.categories-menu .slick-next {
  margin-top: -10px;
}
.categories-menu .slick-prev:before, .categories-menu .slick-next:before {
  opacity: 0.4 !important;
}

.categories-menu .slick-next:before {
  font-size: 30px;
}

</style>