<template>
  <div class="searchbar-mobile-input">
    <input
      v-model="searchText"
      class="searchbar-mobile-input-text"
      type="text"
      :placeholder="placeholder"
      @keyup.enter="search"
    />
    <div
      @click="search"
      class="searchbar-mobile-input-button"
    >
      <img 
        src="@/assets/images/pictos/picto_search.svg" 
        alt="search" 
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchbarMobile',
  props: {
    placeholder: {
      type: String,
      default: 'Search...'
    }
  },
  computed: {
    searchText: {
        get () { 
          return this.$store.state.searchText
        },
        set (value) { 
          this.$store.commit('set', ['searchText', value])
        }
    },
  },
  methods: {
    search() {
      this.$emit('search')
    },
  },
};
</script>

<style>
.searchbar-mobile-input {
  background-color: #f0f0f0;
  position: fixed;
  bottom: 15px;
  left: 0;
  width: 80%;
  text-align: center;
  display: flex;
  align-items: center;
  padding-right: 10px;
  padding-left: 10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px;
}

.searchbar-mobile-input-text {
  border-radius: 20px;
  background-color: #f0f0f0;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
  width: 100%;
}
.searchbar-mobile-input-text:focus {
  border: none;
  outline: none;
}
.searchbar-mobile-input-text:active {
  border: none;
  outline: none;
}
.searchbar-mobile-input-text:focus-visible {
  border: none;
  outline: none;
}

.searchbar-mobile-input-button {
  border-radius: 50%;
  cursor: pointer;
  padding: 3px 7px 5px 7px;
}

.searchbar-mobile-input-button > img {
  width: 20px;
  height: 20px;
}
</style>