import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh/') {
     router.push('/login')
     return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh/', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIUserConnected {

  createAd(token, userId, data) {
    const url = `${apiBaseUrl}/annonces/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.post(url, data, { headers })
  }

  createAdPicture(token, id, data) {
    const url = `${apiBaseUrl}/annonces/${id}/pictures/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }

    return axios_instance.post(url, data, { headers })
  }

  createNewsletterPicture(id, data) {
    const url = `${apiBaseUrl}/newsletters/${id}/pictures/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'multipart/form-data'
    }

    return axios_instance.post(url, data, { headers })
  }

  createNewsletter(userId, data) {
    const url = `${apiBaseUrl}/newsletters/create/${userId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.post(url, data, { headers })
  }

  deleteAd (token, id) {
    const url = `${apiBaseUrl}/annonces/${id}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.delete(url, { headers })
  }

  deleteAdPicture(token, pictureId) {
    const url = `${apiBaseUrl}/annonces/pictures/${pictureId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.delete(url, { headers })
  }

  deleteNewsletter (newsletterId) {
    const url = `${apiBaseUrl}/newsletters/${newsletterId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, { headers })
  }

  deleteNewsletterPicture(pictureId) {
    const url = `${apiBaseUrl}/newsletters/pictures/${pictureId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, { headers })
  }

  favoriteAd (adId)  {
    const url = `${apiBaseUrl}/annonces/${adId}/favories/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.put(url, {}, { headers })
  }

  getAdsLiked(
    {
      page,
      userId,
      categories,
      search,
      published=false
  }) {
    let url = `${apiBaseUrl}/annonces/?page=${page}&onlyLiked=true`
    if (published) {
      url = url + `&published=${published}`
    }

    if (userId) {
      url = url + `&userId=${userId}`
    }

    if (categories) {
      url = url + `&categories=${categories}`
    }

    if (search) {
      url = url + `&search=${search}`
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,

    }
    return axios_instance.get(url, { headers })
  }

  getNewsletters (userId, {search, published, page, paginate}) {
    let url = `${apiBaseUrl}/newsletters/?userId=${userId}&page=${page}&paginate=${paginate}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (published) {
      url = url + `&published=${published}`
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getNewslettersLiked({
    search,
    categories,
    language,
    published,
    page,
    paginate=50
  }) {
    let url = `${apiBaseUrl}/newsletters/?page=${page}&paginate=${paginate}&onlyLiked=true`
    if (search) {
      url = url + `&search=${search}`
    }
    if (language) {
      url = url + `&language=${language}`
    }
    if (categories) {
      url = url + `&categories=${categories}`
    }
    if (published) {
      url = url + `&published=${published}`
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getVideosLiked({
    search,
    categories,
    language,
    published,
    page,
    paginate=50
  }) {
    let url = `${apiBaseUrl}/videos/?page=${page}&paginate=${paginate}&onlyLiked=true`
    if (search) {
      url = url + `&search=${search}`
    }
    if (language) {
      url = url + `&language=${language}`
    }
    if (categories) {
      url = url + `&categories=${categories}`
    }
    if (published) {
      url = url + `&published=${published}`
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getNewsletter (newsletterId) {
    const url = `${apiBaseUrl}/newsletters/${newsletterId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, { headers })
  }

  likeNewsletter (newsletterId)  {
    const url = `${apiBaseUrl}/newsletters/${newsletterId}/likes/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.put(url, {}, { headers })
  }

  likeVideo (videoId)  {
    const url = `${apiBaseUrl}/videos/${videoId}/likes/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.put(url, {}, { headers })
  }

  modifyAd(token, id, data) {
    const url = `${apiBaseUrl}/annonces/${id}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.put(url, data, { headers })
  }

  modifyAdPicture(id, data) {
    const url = `${apiBaseUrl}/annonces/pictures/${id}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.put(url, data, { headers })
  }

  modifyNewsletter (newsletterId, data) {
    const url = `${apiBaseUrl}/newsletters/${newsletterId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, { headers })
  }

  sendAdDetailEmail(token, id, data) {
    const url = `${apiBaseUrl}/annonces/${id}/send-details/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.put(url, data, { headers })
  }
}
