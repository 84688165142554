import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'


export class APIVideo {

  getVideos(page, categories, search) {
    let url = `${apiBaseUrl}/videos/?page=${page}`

    if (categories) {
      url = url + `&categories=${categories.join(',')}`
    }

    if (search) {
      url = url + `&search=${search}`
    }

    const headers = {
      'Content-Type': 'application/json',
    }

    return axios.get(url, { headers })
  }
}
