<template>
<div class="profile-title-component">
  <div 
    v-if="backButton"
    class="profile-back-mobile-button"
  >
    <img
      @click='$router.go(-1)'
      src="@/assets/images/button/back/back_mobile.svg"
      alt="back"
      style="z-index: 999;"
    />
  </div>
  <div class="text-center">
    <strong class="profile-title">
      {{title}}
    </strong>
  </div>
  <div class="text-center mt-2">
    <strong class="profile-subtitle">
      {{subtitle}}
    </strong>
  </div>
</div>
</template>

<script>
export default {
  name: 'ProfileTitleComponent',
  props: {
    backButton: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
  }
}
</script>

<style>
.profile-title-component {
  margin-top: 30px;
}
.profile-title {
  font-size: 30px;
  margin-top: 20px;
}
.profile-subtitle {
  font-size: 16px;
  color: #BCBDBC;
}
</style>