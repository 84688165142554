<template>
  <div class="container">
    <ProfileTitleComponent
      title="Videos Management"
      subtitle="My Videos"
    />
    <div class="row">
    
      <div 
        v-for="video in privatePerson.videos"
        :key="video.id"
        class="col-12 col-md-6 p-2 mt-4"
      >
        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe 
            :src="`https://player.vimeo.com/video/${video['vimeo_id']}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`" 
            frameborder="0" 
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
            style="position:absolute;top:0;left:0;width:100%;height:100%;" 
          />
        </div>

        <div class="video-details mt-2">
          <div>
            <strong>{{video.title}}</strong>
            <br>
            <span 
              v-if="video.categories"
              class="text-muted"
            > 
              {{video.categories?.join(', ')}}
            </span>
          </div>
          <div>

            <img
              @click="$router.push('/learn/videos/edit/' + video.id)"
              src="@/assets/images/video/edit-icon.svg"
              class="video-details--edit-icon"
              width="20"
            />
          </div>

        </div>
        
      </div>
    </div>

    <div class="text-right mt-4">
      <button
        class="profile-validation-button"
        @click="$router.push('/learn/videos/new')"
      >
        Add a new video
      </button>
    </div>
  </div>
</template>

<script src="https://player.vimeo.com/api/player.js"></script>

<script>

import { APIPrivatePersonConnected } from '@/api/APIPrivatePersonConnected'
import ProfileTitleComponent from '@/components/user/profile/ProfileTitleComponent'

const apiPrivatePersonConnected = new APIPrivatePersonConnected()

export default {
  name: 'LearnNewVideo',
  components: { ProfileTitleComponent },
  data() {
    return {
      privatePerson: {
        videos: []
      },
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    }
  },
  async created() {
    this.$store.commit('loadFromAPI')
    await this.getCurrentPrivatePerson()
    this.$store.commit('endLoadingFromAPI')
  },
  methods: {
    async deleteVideo (videoId) {
      this.$store.commit('loadFromAPI')
      await apiPrivatePersonConnected.deleteVideo(videoId)
      await this.getCurrentPrivatePerson()
      this.$store.commit('endLoadingFromAPI')
    },
    async getCurrentPrivatePerson () {
      return apiPrivatePersonConnected.getCurrentPrivatePerson(this.token)
      .then((response) => {
        this.privatePerson = response.data
      }, (error) => {
        throw new Error('getCurrentPrivatePerson API Error : ' + String(error))
      })
    },
  },
}
</script>

<style>
.video-details {
  display: flex;
  justify-content: space-between;
}

.video-details--edit-icon {
  cursor: pointer;
}
</style>