import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'

const AboutFeaturesPage = () => import("../views/information/AboutFeaturesPage")
const PrivacyPage = () => import("../views/information/PrivacyPage")
const TermsPage = () => import("../views/information/TermsPage")
const WhoWeArePage = () => import("../views/information/WhoWeArePage")

const CreateAircraft = () => import("../views/admin/aircraft/CreateAircraft")
const ModifyAircraft = () => import("../views/admin/aircraft/ModifyAircraft")

const AdminCompanies = () => import("../views/admin/company/AdminCompanies")
const CreateCompany = () => import("../views/admin/company/CreateCompany")
const ModifyCompany = () => import("../views/admin/company/ModifyCompany")

const ChatPage = () => import("../views/chat/ChatPage")

const NewslettersPage = () => import("../views/newsletter/NewslettersPage")
const AdminPR = () => import("../views/admin/newsletter/AdminPR")
const CreateNewsletter = () => import("../views/admin/newsletter/CreateNewsletter")
const ModifyNewsletter = () => import("../views/admin/newsletter/ModifyNewsletter")
const UserCreateNewsletter = () => import("../views/user/newsletter/UserCreateNewsletter")
const UserNewslettersList = () => import("../views/user/newsletter/UserNewslettersList")
const UserModifyNewsletter = () => import("../views/user/newsletter/UserModifyNewsletter")

const AdminAds = () => import("../views/admin/ad/AdminAds")
const CreateAd = () => import("../views/admin/ad/CreateAd")
const ModifyAd = () => import("../views/admin/ad/ModifyAd")

const AdminPrivatePersons = () => import("../views/admin/privateperson/AdminPrivatePersons")
const AdminCreatePrivatePerson = () => import("../views/admin/privateperson/AdminCreatePrivatePerson")
const AdminModifyPrivatePerson = () => import("../views/admin/privateperson/AdminModifyPrivatePerson")

const AdminNewsletterSubscribersList = () => import("../views/admin/newslettersubscriber/AdminNewsletterSubscribersList")

const ModifyPassword = () => import("../views/admin/profile/ModifyPassword")

const ModifyQueries = () => import("../views/admin/ai/ModifyQueries")

const CompanyRegistrationCategory = () => import("../views/company/registration/CompanyRegistrationCategory")
const CompanyRegistrationCompany = () => import("../views/company/registration/CompanyRegistrationCompany")
const CompanyRegistrationCompanyHQ = () => import("../views/company/registration/CompanyRegistrationCompanyHQ")
const CompanyRegistrationCompanyGeocode = () => import("../views/company/registration/CompanyRegistrationCompanyGeocode")
const CompanyRegistrationCompanyPictures = () => import("../views/company/registration/CompanyRegistrationCompanyPictures")
const CompanyRegistrationCompanySubCategory = () => import("../views/company/registration/CompanyRegistrationCompanySubCategory")
const CompanyRegistrationCompanyAircraft = () => import("../views/company/registration/CompanyRegistrationCompanyAircraft")
const CompanyRegistrationCompanyAircraftGeocode = () => import("../views/company/registration/CompanyRegistrationCompanyAircraftGeocode")
const CompanyRegistrationConfirmEmail = () => import("../views/company/registration/CompanyRegistrationConfirmEmail")
const CompanyRegistrationAircraftPictures = () => import("../views/company/registration/CompanyRegistrationAircraftPictures")
const CompanyRegistrationValidation = () => import("../views/company/registration/CompanyRegistrationValidation")

const RegistrationPrivatePerson = () => import("../views/privateperson/registration/RegistrationPrivatePerson")

const HomeView = () => import("../views/Home")

const Map  = () => import("../views/map/Map")

const ProfileView = () => import("../views/user/Profile")
const LoginSecurity = () => import("../views/user/LoginSecurity")
const CustomerService = () => import("../views/user/CustomerService")
const ModifyCompanyAddress = () => import("../views/user/company/ModifyCompanyAddress")
const ModifyCompanyQualificationAircrafts = () => import("../views/user/company/ModifyCompanyQualificationAircrafts")
const ModifyCompanyProfile = () => import("../views/user/company/ModifyCompanyProfile")
const CompanyCreateAircraft = () => import("../views/user/company/aircraft/CompanyCreateAircraft")
const CompanyModifyAircraft = () => import("../views/user/company/aircraft/CompanyModifyAircraft")
const UserAdsList = () => import("../views/user/ad/UserAdsList")
const UserModifyAd = () => import("../views/user/ad/UserModifyAd")
const UserCreateAd = () => import("../views/user/ad/UserCreateAd")
const ModifyPrivatePersonProfile = () => import("../views/user/privateperson/ModifyPrivatePersonProfile")
const ModifyPrivatePersonAddress = () => import("../views/user/privateperson/ModifyPrivatePersonAddress")

const ResetPassword = () => import("../views/user/ResetPassword")

const AdsList = () => import("../views/ad/AdsList")
const AdPage = () => import("../views/ad/AdPage")

const AdminDonationsList = () => import("../views/admin/donation/AdminDonationsList")

import LearnPage from "@/views/video/LearnPage"
import LearnMyVideos from "@/views/user/learn/LearnMyVideos"
import LearnNewVideo from "@/views/user/learn/LearnNewVideo"
import LearnModifyVideo from "@/views/user/learn/LearnModifyVideo"

const PageNotFound = () => import("../views/PageNotFound")


Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const isUserGroup = (group_name) => {
  var userGroups = store.getters.getUserGroups
  var userGroupsArray = []
  var is_contained = false
  if (userGroups) {
    try {
      var userGroupsJson = JSON.parse(userGroups)
      for (var i = 0; i < userGroupsJson.length; i++) {
        userGroupsArray.push(
          userGroupsJson[i]['name']
        )
      }
    }
    catch(error) {
      return is_contained
    }
  }

  for (var j = 0; j < group_name.length; j++) {
    if (userGroupsArray.indexOf(group_name[j]) > -1) {
      is_contained = true
    }
  }
  return is_contained
}

const isConnectedAdmin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['ADMIN'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/')
  }
}

const isConnectedCompany = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['COMPANY'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/')
  }
}

const isConnectedPrivatePerson = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (isUserGroup(['PRIVATE'])) {
      next()
      return
    }
    else {
      next('/')
    }
  }
  else {
    next('/')
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatPage,
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
  },
  {
    path: '/about-features',
    name: 'about-features',
    component: AboutFeaturesPage,
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsPage,
  },
  {
    path: '/who-we-are',
    name: 'who-we-are',
    component: WhoWeArePage,
  },
  {
    path: '/news',
    name: 'news',
    component: NewslettersPage,
  },
  {
    path: '/admin/ads',
    name: 'admin-ads',
    component: AdminAds,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/ads/create',
    name: 'admin-ads-create-ad',
    component: CreateAd,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/ads/:id',
    name: 'admin-ads-modify',
    component: ModifyAd,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/corporations',
    name: 'admin-corporations',
    component: AdminCompanies,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/corporations/create',
    name: 'admin-company-create',
    component: CreateCompany,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/corporations/:companyId/aircrafts/create',
    name: 'admin-aircraft-create',
    component: CreateAircraft,
    beforeEnter: isConnectedAdmin
  },
  
  {
    path: '/admin/corporations/:id',
    name: 'admin-company-modify',
    component: ModifyCompany,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/corporations/:companyId/aircrafts/:id',
    name: 'admin-aircraft-modify',
    component: ModifyAircraft,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/donations',
    name: 'admin-donations',
    component: AdminDonationsList,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/pr',
    name: 'admin-pr',
    component: AdminPR,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/pr/create',
    name: 'admin-pr-create',
    component: CreateNewsletter,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/pr/:id',
    name: 'admin-pr-modify',
    component: ModifyNewsletter,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/private-persons',
    name: 'admin-private-persons',
    component: AdminPrivatePersons,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/private-persons/create',
    name: 'admin-private-person-create',
    component: AdminCreatePrivatePerson,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/private-persons/:id',
    name: 'admin-private-person-modify',
    component: AdminModifyPrivatePerson,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/newsletter-subscribers',
    name: 'admin-newsletter-subscribers',
    component: AdminNewsletterSubscribersList,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/queries',
    name: 'admin-queries-modify',
    component: ModifyQueries,
    beforeEnter: isConnectedAdmin
  },
  {
    path: '/admin/password/modify',
    name: 'admin-password-modify',
    component: ModifyPassword,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/ads',
    name: 'ads',
    component: AdsList,
  },
  {
    path: '/ads/:id',
    name: 'ad',
    component: AdPage,
  },
  {
    path: '/company/registration/category',
    name: 'company-registration-category',
    component: CompanyRegistrationCategory,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/company/registration/information',
    name: 'company-registration-company',
    component: CompanyRegistrationCompany,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/company/registration/confirm-email',
    name: 'company-registration-confirm-email',
    component: CompanyRegistrationConfirmEmail,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/hq',
    name: 'company-registration-company-hq',
    component: CompanyRegistrationCompanyHQ,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/geocode',
    name: 'company-registration-company-geocode',
    component: CompanyRegistrationCompanyGeocode,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/pictures',
    name: 'company-registration-company-pictures',
    component: CompanyRegistrationCompanyPictures,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/qualifications',
    name: 'company-registration-company-qualifications',
    component: CompanyRegistrationCompanySubCategory,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/aircraft',
    name: 'company-registration-company-aircraft',
    component: CompanyRegistrationCompanyAircraft,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/aircraft/:aircraftPk/geocode',
    name: 'company-registration-aircraft-geocode',
    component: CompanyRegistrationCompanyAircraftGeocode,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/aircraft/:aircraftPk/pictures',
    name: 'company-registration-aircraft-pictures',
    component: CompanyRegistrationAircraftPictures,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/registration/validation',
    name: 'company-registration-validation',
    component: CompanyRegistrationValidation,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/account',
    name: 'profile',
    component: ProfileView,
    beforeEnter: ifAuthenticated 
  },
  {
    path: '/account/login-security',
    name: 'profile-login-security',
    component: LoginSecurity,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/account/customer-service',
    name: 'profile-customer-service',
    component: CustomerService,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/private-person/account/information',
    name: 'private-person-profile-modify-information',
    component: ModifyPrivatePersonProfile,
    beforeEnter: isConnectedPrivatePerson
  },
  {
    path: '/private-person/account/address',
    name: 'private-person-profile-modify-address',
    component: ModifyPrivatePersonAddress,
    beforeEnter: isConnectedPrivatePerson
  },
  {
    path: '/company/account/information',
    name: 'company-profile-modify-information',
    component: ModifyCompanyProfile,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/account/address',
    name: 'company-profile-modify-address',
    component: ModifyCompanyAddress,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/account/qualifications-fleet',
    name: 'company-profile-modify-qualifications-fleet',
    component: ModifyCompanyQualificationAircrafts,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/account/qualifications-fleet/aircrafts/create',
    name: 'company-profile-create-aircraft',
    component: CompanyCreateAircraft,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/company/account/qualifications-fleet/aircrafts/:id/modify',
    name: 'company-profile-modify-aircraft',
    component: CompanyModifyAircraft,
    beforeEnter: isConnectedCompany
  },
  {
    path: '/password/reset/confirmation',
    name: 'reset-password-confirmation',
    component: ResetPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/private-person/registration',
    name: 'private-person-registration',
    component: RegistrationPrivatePerson,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/user/account/ads',
    name: 'user-profile-ads',
    component: UserAdsList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user/account/ads/:id/modify',
    name: 'user-profile-modify-ad',
    component: UserModifyAd,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user/account/ads/create',
    name: 'user-profile-create-ad',
    component: UserCreateAd,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user/account/posts/create',
    name: 'user-profile-create-newsletter',
    component: UserCreateNewsletter,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user/account/posts',
    name: 'user-profile-newsletters',
    component: UserNewslettersList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/user/account/posts/:id/modify',
    name: 'user-profile-modify-newsletter',
    component: UserModifyNewsletter,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/learn',
    name: 'LearnPage',
    component: LearnPage,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/learn/my-videos',
    name: 'learn-videos',
    component: LearnMyVideos,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/learn/videos/new',
    name: 'learn-new-video',
    component: LearnNewVideo,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/learn/videos/edit/:id',
    name: 'learn-edit-video',
    component: LearnModifyVideo,
    beforeEnter: ifAuthenticated
  },
  { path: "*", component: PageNotFound }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
