import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh/') {
     router.push('/login')
     return Promise.reject(error)
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh/', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIPrivatePersonConnected {

  createPrivatePersonPicture(token, id, data) {
    const url = `${apiBaseUrl}/private-persons/${id}/pictures/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'multipart/form-data'
    }

    return axios_instance.post(url, data, { headers })
  }

  createVideo (data) {
    const url = `${apiBaseUrl}/videos/`
    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.post(url, data, { headers })
  }

  deletePrivatePersonPicture(token, pictureId) {
    const url = `${apiBaseUrl}/private-persons/pictures/${pictureId}/`

    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.delete(url, { headers })
  }

  deleteVideo(videoId) {
    const url = `${apiBaseUrl}/videos/${videoId}/`
    const headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, { headers })
  }

  getCurrentPrivatePerson(token) {
    let url = `${apiBaseUrl}/private-persons/current/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getVideo(id) {
    let url = `${apiBaseUrl}/videos/${id}/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyPrivatePerson(token, pk, data) {
    let url = `${apiBaseUrl}/private-persons/${pk}/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  modifyVideo (id, data) {
    let url = `${apiBaseUrl}/videos/${id}/`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(store.state.auth.token).access,
      'Content-Type': 'multipart/form-data'
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }
}
